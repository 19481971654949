import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import colors from "@styles/variables/nextautowarrantyVariables.module.scss";
import Image from "next/image";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";

import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";

export default function MainBanner(): ReactElement {
    const { visitDetails, domain } = useDomainContext();
    const { query } = useRouter();

    const theCarShouldShow = (gRouter: string) => {
        if (!gRouter) {
            return "generic";
        }
        const arrImg = [
            "chevrolet",
            "ford",
            "nissan",
            "dodge",
            "jeep",
            "gmc",
            "hyundai",
            "toyota",
            "bmw",
            "mercedes",
            "kia",
            "honda",
            "audi",
            "lexus",
            "volkswagen",
            "subaru",
            "mazda",
        ];
        const car = arrImg.find((image) => {
            if (gRouter.trim().toLowerCase().indexOf(image) !== -1) {
                return image;
            }
        });

        return car ?? "generic";
    };

    const isFormDisplayed = finalShowForm(
        query.showList as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div className={styles["main-banner-section"]}>
                    <div className={styles["main-banner"]}>
                        <Form
                            classes={{
                                formClassName: styles["form"],
                                stepTitleClassName: styles["step-title"],
                                stepDescriptionClassName:
                                    styles["step-description"],
                                formButtonClassName: styles["form-button"],
                                formFieldClassName: styles["form-field"],
                                stepClassName: styles["step"],
                                formStepInnerClassName: styles["steps-inner"],
                                fieldsClassName: {
                                    radio: styles["radio-field"],
                                    checkbox: styles["checkbox-field"],
                                    all: styles["field"],
                                },
                                fieldsWrapperClassName:
                                    styles["fields-wrapper"],
                                tcpaClassName: styles["tcpa"],
                                labelAsTitleClassName:
                                    styles["label-as-step-title"],
                                fieldNoteClassName: styles["field-note"],
                                fieldErrorMessageClassName:
                                    styles["field-error-message"],
                                sellingPointClassName: styles["selling-point"],
                            }}
                            colors={{
                                primaryColor: colors.gorseYellowOrange,
                                progressBar: "#9B9B9B",
                            }}
                            popupStyle={{
                                actionBtnBgColor: "#ff9800",
                                actionBtnColor: "#fff",
                                iconColor: "#ff9800",
                            }}
                            showProgress={true}
                            goToThankYouPage={true}
                            sellingPoint={
                                visitDetails?.region
                                    ? `${visitDetails?.region}'s Best Extended Car Warranty`
                                    : ""
                            }
                            sellingPointsSteps="FIRST_STEP"
                            dynamicThankYouFields={[
                                "firstName",
                                "manufacturingYear",
                                "vehicleMake",
                                "vehicleModel",
                            ]}
                        />

                        <div className=" relative w-[330px] h-[330px] sm:w-[470px] sm:h-[470px] md:w-[500px] md:h-[500px] lg:w-[600px]  lg:h-[600px]     lg:mt-10">
                            <div className="absolute w-full h-full">
                                <Image
                                    src={
                                        "/autowarrantyprices/assets/images/png-bg-2-.png"
                                    }
                                    layout="fill"
                                    alt="side img"
                                />
                            </div>

                            <div className={`${styles["animationCar"]}`}>
                                <Image
                                    src={`/autowarrantyprices/assets/images/${theCarShouldShow(
                                        query.g as string,
                                    )}.png`}
                                    layout="fill"
                                    alt="side img car"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <TextBlockOffer
                    offerContainerStyle={styles["offerContainer"]}
                />
            )}
        </>
    );
}
